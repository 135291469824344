import { List } from "@phosphor-icons/react";
import jvrisLogo from "../../assets/jvris-logo.png";
import { SharedState } from "../../context/SharedContext";
import { PROFILES } from "../../enums/PROFILES.enum";
import theme from "../../globalStyle/theme";
import { SelectUser } from "./components/SelectUser";
import { HeaderProps } from "./interface/header.interface";
import * as S from "./styles";

export const Header = ({ ToogleSideMenu }: HeaderProps) => {
    const { user, homeLink } = SharedState();

    return (
        <S.HeaderContainer>
            <S.LogoContainer to={homeLink}>
                <S.Logo
                    src={jvrisLogo}
                    alt="Logotipo do sistema Jvris de um templo branco com três pilares."
                />
            </S.LogoContainer>

            <S.IconContainer onClick={ToogleSideMenu}>
                <List size={16} color={theme.colors.lightGrey} weight="fill" />
            </S.IconContainer>

            {+user["jvris.User.Perfil"].includes(
                PROFILES.ANALISTA.toString()
            ) ||
            +user["jvris.User.Perfil"].includes(
                PROFILES.ASSESSOR_PROCURADOR.toString()
            ) ? (
                <S.ContainerSelect>
                    <SelectUser />
                </S.ContainerSelect>
            ) : (
                ""
            )}
        </S.HeaderContainer>
    );
};
